.CookieAgreement {
    font-size: 18px;
}

.CookieAgreement a {
    color: #fff;
}

.CookieAgreement button {
    color: #000;
}

.MuiSnackbarContent-action button {
    color: #f1ba16;
}
