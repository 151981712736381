.cardContainer {
    background-color: #fff;
    border-radius: 4px;
    width: 80%;
    height: auto;
    padding: 4px;
    text-decoration: none;
    color: rgb(46, 41, 41);
}

.cardTitle {
    text-align: center;
    margin: 10px;
    font-weight: 600;
}

.iframeContainer {
    border-radius: 4px;
    overflow: hidden;
}

@media screen and (min-width: 700px) {
.buttonGoBack {
        position: absolute;
        left: 10px;
        top: 50px;
        z-index: 5;
}

.cardContainer {
    width: 60%;
}
}

