body {
  background-color: #000000b3;
}

/* .container {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  background-position: center;
  background-size: cover;
  color: #fff;
} */

h1.text {
  background-color: hsla(0,0%,88%,.8);
  color: #000;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding: 10px 25px;
  margin-top: 0;
  margin-bottom: 0;
}

/* .container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
} */

.background.image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-content: center;
  background-repeat: no-repeat;
  /* background: no-repeat center center fixed; */
  /* background-size: cover; */
}

.loader {
  transition: all 0.5s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000e6;
}

.captcha.txt-center {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.loader.loading {
  opacity: 1;
  z-index: 1;
  overflow: hidden;
}

.test.MuiBox-root > div {
  height: 100vh !important;
}

@media screen and (max-width: 700px) {
  .background.image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-size: cover;
    background-position: center;
    align-content: center;
    /* height: 100vh; */
    background-repeat: no-repeat;

    /* background: no-repeat center center fixed;
    background-size: cover; */

  }

  h1.text {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
    margin-bottom: 0;
    /* background-color: unset; */
    border-radius: 0px;
    font-size: 18px;
    /* max-width: 600px; */
    max-width: 350px;
    background-color: rgb(225, 225, 225, 0.8);
    color: #000;
    /* height: 50vh; */
  }
}

body {
  margin: 0;
  scroll-behavior: smooth;
}

.page-scroller-info {
  position: fixed;
  z-index: 99;
  display: flex;
}

.info-block {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.info-block p {
  margin: 0;
}

.page {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-number {
  font-size: 300%;
  color: #f5f0e1;
}

.page-nav {
  height: 50px;
  position: fixed;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
}

.page-nav-button {
  padding: 10px;
  margin: 10px;
  color: white;
  border-bottom: 2px solid white;
  cursor: pointer;
}
.bg-blue {
  background-color: #1e3d59;
}
.bg-red {
  background-color: #ffc13b;
}
.bg-green {
  background-color: #ff6e40;
}

.fp-warning,
.fp-watermark {
  display: none;
}

