.container {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding-bottom: 30px;
}

.businessCardContainer {
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 700px) {
.businessCardContainer {
        margin: 0;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.businessCardImg {
    height: 280px;
    border-radius: 280px;
    margin-right: 15px;
}

@media screen and (max-width: 700px) {
    .businessCardImg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        }
}

.personalData {
    display: inline-block;
    border-left: 3px solid #03c57f;
    font-family: sans-serif;
    padding: 10px 10px 10px 15px;
}

.infoContainer {
    margin: 30 px;
    padding: 30 px;
}

.contactName {
    display: flex;
    line-height: 1;
    align-items: center;
    font-size: 3em;
    color: #03c57f;
    font-weight: bold;
    margin-bottom: 10px;
}

.contactPosition {
    display: flex;
    line-height: 1;
    align-items: center;
    font-size: 2.4em;
    margin-bottom: 20px;
    color: #555;
}

.website {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 1.4em;
}

.feedback {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #03c57f;
    font-size: 1.8em;
    font-weight: 600;
}

.socialNetworksContainer {
    display: flex;
}

.link {
    display: inline-block;
    text-decoration: none;
    margin-right: 15px;
    font-size: 1.5em;
}

.socialNetworkIcon {
    height: 50px;
    margin-right: 20px;
}

