.container {
    background-color: #fff;
    border-radius: 4px;
    width: 80%;
    height: auto;
    padding: 4px;
    text-decoration: none;
    color: rgb(46, 41, 41);
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    
}

.cardTitle {
    text-align: center;
    margin: 10px;
    font-weight: 600;
}

.iframeContainer {
    border-radius: 4px;
    overflow: hidden;
}

.title {
    text-align: center;
    color: #fff;
}


@media screen and (min-width: 700px) {

    .container {
        width: 60%;
    }
}

